import React, { useContext } from 'react';
import PortableText from 'react-portable-text';
import { BgImage } from 'gbimage-bridge';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import { EmailInput } from '../Home/EmailInput';
import { WaveUpHero } from '../WaveSVGs/WaveUpHero';
import { HeroBody } from '../CustomStyles/Typography';
import { HeroCtaButton } from './HeroCtaButton';
import FaqSearchContext from '../../context/FaqSearchContext';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.white,
    marginBottom: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  header: {
    lineHeight: 1.1,
    marginTop: '50px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.2rem',
      marginTop: '20px',
    },
  },
  headerFS: {
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.3,
    textTransform: 'uppercase',
    fontFamily: ['Roboto', 'sans-serif'].join(),
    [theme.breakpoints.down('md')]: {
      // marginBottom: '1rem',
      fontSize: '1.3rem',
    },
    // [theme.breakpoints.down('xs')]: {
    // 	fontSize: '1.5rem',
    // },
  },
  imgCont: {
    padding: '6rem 0',
    maxWidth: '100vw',
    height: '100%',
    overflowX: 'hidden',
    backgroundSize: 'cover',
    background:
      'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',
    [theme.breakpoints.down('md')]: {},

    [theme.breakpoints.down('xs')]: {
      padding: '3rem 0',
      backgroundPosition: 'right',
    },
  },
  imgRight: {
    marginLeft: '-10rem',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '-9rem',
      height: '560px',
    },
    // [theme.breakpoints.down('md')]: {
    // 	marginLeft: '-4rem',
    // 	height: '380px',
    // },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-8rem',
      height: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
      height: '260px',
    },
  },
  star: {
    marginRight: '.5rem',
    color: '#FFCA43',
  },
  wave: {
    [theme.breakpoints.down('lg')]: {},
    // [theme.breakpoints.down('md')]: {
    // 	marginTop: '-7rem',
    // },
    // [theme.breakpoints.down('sm')]: {
    // 	marginTop: '-7rem',
    // },
    [theme.breakpoints.down('xs')]: {},
  },
  inputLabel: {
    '&.shrink': {
      backgroundColor: 'white',
      padding: '2px',
      borderRadius: '5px',
    },
  },
  searchIconBackground: {
    height: '56px',
    width: '56px',
    position: 'relative',
    left: '22px',
    background: '#002D5C',
  },
  searchIcon: {
    color: '#fff',
    height: '56px',
    width: '56px',
    padding: '6px',
    background: '#002D5C',
    borderRadius: '0 4px 4px 0',
  },
}));

export const Hero = ({
  hero,
  accentColor,
  leadership,
  login,
  faq,
  finServ,
  noWave,
  company,
}) => {
  const classes = useStyles();
  const xs = useMediaQuery('(max-width: 481px)');
  const md = useMediaQuery('(max-width: 960px)');

  const { handleChange, defaultFaq } = useContext(FaqSearchContext);
  return (
    <>
      <BgImage
        className={classes.imgCont}
        image={hero?.backgroundImage?.asset?.gatsbyImageData}
        style={{
          // backgroundImage: `url(${hero?.backgroundImage?.asset?.gatsbyImageData.images.fallback.src})`,
          lineHeight: '0',
          overflow: 'hidden',
        }}
      >
        <Container fixed>
          <Grid container direction='row' justifyContent='center'>
            <Grid
              container
              item
              direction='column'
              justify='center'
              alignItems={md ? 'flex-start' : 'center'}
              xs={12}
              md={faq || finServ ? 9 : 10}
            >
              {hero?._rawContent.map((content, index) => (
                <PortableText
                  key={index}
                  content={content}
                  className={classes.text}
                  serializers={{
                    h1: ({ children }) => (
                      <Typography
                        variant='h1'
                        className={classes.header}
                        style={{
                          marginTop: finServ ? 0 : '',
                          paddingBottom: company && '4rem',
                        }}
                      >
                        {children}
                      </Typography>
                    ),
                    h2: ({ children }) => (
                      <Typography
                        variant='h2'
                        className={classes.header}
                        style={{ fontSize: login && '1.5rem' }}
                      >
                        {children}
                      </Typography>
                    ),
                    h4: ({ children }) => (
                      <Typography
                        variant='h4'
                        className={finServ ? classes.headerFS : classes.header}
                      >
                        {children}
                      </Typography>
                    ),
                    normal: ({ children }) => (
                      <HeroBody leadership={leadership}>{children}</HeroBody>
                    ),
                    sup: ({ children }) => (
                      <span style={{ verticalAlign: 'super', fontSize: '40%' }}>
                        {children}
                      </span>
                    ),
                    strong: ({ children }) => (
                      <>
                        {company && <br />}
                        <span style={{ color: '#5faff2' }}>{children}</span>
                      </>
                    ),
                  }}
                />
              ))}{' '}
            </Grid>
            {!!hero.ctaText || faq?.length || hero.ctaButtonText ? (
              <Grid item xs={12} md={6}>
                {faq?.length ? (
                  <Grid container direction='row' style={{ padding: '2rem 0' }}>
                    <TextField
                      variant='outlined'
                      onKeyUp={(e) => handleChange(e, defaultFaq, faq)}
                      fullWidth
                      label='SEARCH FOR YOUR TOPIC'
                      style={{
                        borderRadius: '4px',
                        background: '#FFF',
                        boxShadow:
                          '8px 8px 10px rgb(50 50 93 / 15%), 0 2px 5px rgb(0 0 0 / 12%)',
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabel,
                          shrink: 'shrink',
                        },
                      }}
                      InputProps={{
                        type: 'search',
                        endAdornment: (
                          <InputAdornment
                            className={classes.searchIconBackground}
                          >
                            <SearchTwoToneIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                ) : hero.ctaText ? (
                  <EmailInput
                    accentColor={accentColor}
                    ctaText={hero.ctaText}
                    fs={finServ}
                  />
                ) : hero.ctaButtonText ? (
                  <HeroCtaButton hero={hero} />
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        </Container>
        {!noWave ? <WaveUpHero height='213' width='100%' fill='white' /> : null}
      </BgImage>
    </>
  );
};
